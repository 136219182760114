import React, { useEffect, useState } from 'react';

import Modal from './components/modal.component';
import Login from './components/login.component';
import Form from './components/form.component';
import Credits from './components/credits.component';

import './App.css';

const App = () => {

    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
    
            if (token) {
                // Simulating a delay for token validation

                setLoading(true);

                // Check if the token is valid on the server-side
                fetch(process.env.REACT_APP_API_URL + '/api/validateToken', {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ token })
                })
                .then((response) => {
                    if (response.ok) {
                        setAuthenticated(true);
                    } else {
                        setAuthenticated(false);
                    }
                })
                .catch((error) => {
                    console.error('Error occurred during token validation:', error);
                    setAuthenticated(false);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
      }, []);

    //callback function to be called when login is successful
    const handleLoginSuccess = (token: string) => {
        localStorage.setItem('token', token);
        setAuthenticated(true);
    };

    const handleLogout = () => {
        setAuthenticated(false);
    };

    return (
        <div>
        {loading ? (
            <Modal />
        ) : null}

                <div>
                    {authenticated ? (
                        <Form onLogout={handleLogout} />
                        ) : null}
                        {!authenticated && (
                            <Login onSuccess={handleLoginSuccess} />
                        )}
                </div>
                <Credits />
        </div>
    );
}

export default App;
