import React from 'react';

// LoaderModal component
const Modal = () => {
  return (
    <div className="loader-modal">
      <div className="loader"></div>
    </div>
  );
};

export default Modal;