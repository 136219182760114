import React, { useState } from 'react';

import Credits from './credits.component';

import '../App.css';

import lockIcon from '../img/lock-icon.png';
import Modal from './modal.component';

import haitiFlag from '../img/haiti-flag.gif';

interface FormData {

    username: string;
    password: string;
}

const Login: React.FC<{onSuccess: (token:string) => void}> = ( {onSuccess} : any) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const [formData, setFormData] = useState<FormData>({
        username: '',
        password: ''
    });

    // Function to handle form submission
    const handleSubmit = async (e: React.FormEvent) => {

        e.preventDefault();

        setLoading(true);

        try {
            //send login request to the server
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            //check if authentication was successful
            if (response.ok) {
                const data = await response.json();
                const token = data.token;
                console.log(token);
                onSuccess(token); //call the onSuccess callback
            } else {
                console.error('Authentication failed');
                setError('Authentication failed'); // Set the error message
            }
        } catch (error) {
            console.error('Error occurred during login:', error);
            setError('An error occurred during login: ' + error); // Set the error message
        } finally {
            setLoading(false);
        }
    };

  // Function to handle input change
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };


    return (
        <>
        {loading ? (
            <Modal />
        ) : null}
            <div id="login-app-container">
                <div id="haiti-flag">
                    <img id="haiti-flag-img" src={haitiFlag} alt="Haitian Flag" />
                </div>
                <div id="form-container">
                    {error && (
                        <div id="error-login">
                            {error}
                        </div>
                    )}
                    <form onSubmit={handleSubmit}>
                        <input 
                            type="text"
                            name="username"
                            placeholder="imel"
                            value={formData.username}
                            onChange={handleChange}
                            required
                        />
                        <br></br>
                        <br></br>
                        <input
                            type="password"
                            name="password"
                            placeholder="modpas"
                            value={formData.password}
                            onChange={handleChange}
                            required
                        />
                        <br></br>
                        <br></br>
                        <button type="submit" id="login-button">
                            <img width="24px" src={lockIcon}/> &nbsp;Konekte
                        </button>
                    </form>
                </div>
            </div>
            
            <Credits />
        </>
    );
}

export default Login;
