import React, { useState } from 'react';
import Modal from './modal.component';

import bandana from '../img/bandana-nobg.png';

interface FormData {
    ipaddress: string;
}

const Web: React.FC<{}> = () => {

    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState<FormData>({
        ipaddress: ''
    });
    const [result, setResult] = useState<number | null>(null);
    const [error, setError] = useState<string | null>(null);

    const [static_ip_score, setStaticIpScore] = useState<string | null>(null); // [1
    const [country, setCountry] = useState<string | null>(null);
    const [city, setCity] = useState<string | null>(null);
    const [subdivision, setSubdivision] = useState<string | null>(null);
    const [subdivision_confidence, setSubdivision_confidence] = useState<string | null>(null);
    const [postal, setPostal] = useState<string | null>(null);
    const [latitude, setLatitude] = useState<string | null>(null);
    const [longitude, setLongitude] = useState<string | null>(null);
    const [timezone, setTimezone] = useState<string | null>(null);
    const [accuracy, setAccuracy] = useState<string | null>(null);
    const [network, setNetwork] = useState<string | null>(null);
    const [autonomous_system_number, setAutonomous_system_number] = useState<string | null>(null);
    const [autonomous_system_organization, setAutonomous_system_organization] = useState<string | null>(null);
    const [isp, setIsp] = useState<string | null>(null);
    const [organization, setOrganization] = useState<string | null>(null);
    
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value
        }));
      };

    const handleSubmit = async (e: React.FormEvent) => {
        
        e.preventDefault();

        let token: string | null = '';

        try {
            token = localStorage.getItem('token'); // Retrieve token from localStorage
        
            if (!token) {
              setError('Invalid token... Please login again...');
              setResult(null);
              return;
            }
        } catch(error) {
            console.error('Error occurred during token validation:', error);
            setError('An error occurred');
            setResult(null);
        }
        setLoading(true);
        try {
          const response = await fetch(process.env.REACT_APP_API_URL + '/api/insights', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(formData)
          });
    
          if (response.ok) {

            const data = await response.json();

            setStaticIpScore(data.static_ip_score);
            setResult(data.result);
            setCountry(data.country);
            setCity(data.city);
            setSubdivision(data.subdivision);

            console.log(data.subdivision);

            setSubdivision_confidence(data.subdivision_confidence);
            setPostal(data.postal);
            setLatitude(data.latitude);
            setLongitude(data.longitude);
            setTimezone(data.timezone);
            setAccuracy(data.accuracy_radius);
            setNetwork(data.network);
            setAutonomous_system_number(data.autonomous_system_number);
            setAutonomous_system_organization(data.autonomous_system_organization);
            setIsp(data.isp);
            setOrganization(data.organization);

            setError(null);
          } else {
            const errorMessage = await response.text();
            setError(errorMessage);
            setResult(null);
          }
        } catch (error) {
          console.error('Error occurred during API call:', error);
          setError('An error occurred: ' + error);
          setResult(null);
        } finally {
          setLoading(false);
        }
      };

    return (
        <>
        {loading ? (
            <Modal />
            ) : null}
            <div id="app-container">

                {result !== null && !error && 
                <>
                    <div id="score">Sèvis entènèt: <span style={{fontStyle:'italic',fontWeight:'bold'}}>{result}</span></div>
                    <div id="data-result">
                        STATIC IP SCORE: <span className="data-line">{static_ip_score}</span>
                        <br></br>
                        COUNTRY: <span className="data-line">{country}</span>
                        <br></br>
                        CITY: <span className="data-line">{city}</span>
                        <br></br>
                        SUBDIVISION: <span className="data-line">{subdivision}</span>
                        <br></br>
                        SUBDIVISION CONFIDENCE: <span className="data-line">{subdivision_confidence}</span>
                        <br></br>
                        POSTAL: <span className="data-line">{postal}</span>
                        <br></br>
                        LAT: <span className="data-line">{latitude}</span>
                        <br></br>
                        LONG: <span className="data-line">{longitude}</span>
                        <br></br>
                        TIMEZONE: <span className="data-line">{timezone}</span>
                        <br></br>
                        ACCURACY RADIUS: <span className="data-line">{accuracy}</span>
                        <br></br>
                        NETWORK: <span className="data-line">{network}</span>
                        <br></br>
                        AUTONOMOUS SYSTEM NUMBER: <span className="data-line">{autonomous_system_number}</span>
                        <br></br>
                        AUTONOMOUS SYSTEM ORGANIZATION: <span className="data-line">{autonomous_system_organization}</span>
                        <br></br>
                        ISP: <span className="data-line">{isp}</span>
                        <br></br>
                        ORGANIZATION: <span className="data-line">{organization}</span>
                    </div>
                    <br></br>
                </>
                }

                <input type="text" id="ip-address-input" name="ipaddress" value={formData.ipaddress} onChange={handleInputChange} placeholder="127.0.0.1" required />

                <br></br>
                <br></br>

                <button type="submit" id="search-button" onClick={handleSubmit}>
                    Jwenn Li
                </button>

                <div id="bandana">
                    <img src={bandana} alt="bandana" height={'120px'}/>
                </div>
            </div>

            {error && <p color="red">Error: {error}</p>}
        </>
    );
};

export default Web;