import React, { useState } from 'react';

import Score from './score.component';
import Web from './web.component';
import Email from './email.component';
import Phone from './phone.component';
import Ip from './ip.component';
import Transaction from './transaction.component';

const Form: React.FC<{ onLogout: () => void }> = ({ onLogout }) => {

    const [selectedMenu, setSelectedMenu] = useState(0);
    const handleMenuSelected = (menuNumber: number) => {
        setSelectedMenu(menuNumber);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        onLogout();
    };

    return (
        <>
            <div id="menu-top-left">
                <ul>
                    <button className="menu-item" onClick={() => handleMenuSelected(0)}>Nòt</button>
                    <button className="menu-item" onClick={() => handleMenuSelected(1)}>Sèvis entènèt</button>
                    <button className="menu-item" onClick={() => handleMenuSelected(2)}>Imel</button>
                    <button className="menu-item" onClick={() => handleMenuSelected(3)}>Telefòn</button>
                    <button className="menu-item" onClick={() => handleMenuSelected(4)}>Ip</button>
                    <button className="menu-item" onClick={() => handleMenuSelected(5)}>Tranzaksyon Yo</button>
                </ul>
            </div>

            {selectedMenu === 0 && <Score />}
            {selectedMenu === 1 && <Web />}
            {selectedMenu === 2 && <Email />}
            {selectedMenu === 3 && <Phone />}
            {selectedMenu === 4 && <Ip />}
            {selectedMenu === 5 && <Transaction />}

            <button id="logout-button" onClick={handleLogout}>Dekonekte</button>
        </>
    );
};

export default Form;