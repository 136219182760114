import React, { useState } from 'react';
import Modal from './modal.component';

import bandana from '../img/bandana-nobg.png';

interface FormData {
    phone: string;
}

const Phone: React.FC<{}> = () => {

    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState<FormData>({
        phone: '',
    });
    const [score, setScore] = useState<number | null>(null);
    const [error, setError] = useState<string | null>(null);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value
        }));
      };

    const handleSubmit = async (e: React.FormEvent) => {
        
        e.preventDefault();

        let token: string | null = '';

        try {
            token = localStorage.getItem('token'); // Retrieve token from localStorage
        
            if (!token) {
              setError('Invalid token... Please login again...');
              setScore(null);
              return;
            }
        } catch(error) {
            console.error('Error occurred during token validation:', error);
            setError('An error occurred');
            setScore(null);
        }
        setLoading(true);
        try {
          const response = await fetch(process.env.REACT_APP_API_URL + '/api/seon/phone', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(formData)
          });
    
          if (response.ok) {
            const data = await response.json();
            if (data.error) {
                setError("move fòman an se pa bon");
                setScore(null);
                return;
            } else {
                setScore(data.result);
                setError(null);
            }
          } 
        } catch (error) {
          console.error('Error occurred during API call:', error);
          setError('An error occurred: ' + error);
          setScore(null);
        } finally {
          setLoading(false);
        }
      };

    return (
        <>
        {loading ? (
            <Modal />
            ) : null}
            <div id="app-container">

                {error && (
                    <div id="error-login">
                        {error}
                    </div>
                )}

                {score !== null && !error && <div id="score">Nòt: <span style={{fontStyle:'italic',fontWeight:'bold'}}>{score}</span></div>}

                <input type="text" id="phone-input" name="phone" value={formData.phone} onChange={handleInputChange} placeholder="+1(514)1234567" required />
                
                <br></br>
                <br></br>

                <button type="submit" id="search-button" onClick={handleSubmit}>
                    Jwenn Li
                </button>

                <div id="bandana">
                    <img src={bandana} alt="bandana" height={'120px'}/>
                </div>
            </div>

            {error && <p color="red">Error: {error}</p>}
        </>
    );
};

export default Phone;