import React, { useState } from 'react';
import Modal from './modal.component';

import bandana from '../img/bandana-nobg.png';

interface FormData {
    email: string;
    country_billing: string;
    postal_billing: string;
    country_shipping: string;
    postal_shipping: string;
  }
  

const Score: React.FC<{}> = () => {

    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState<FormData>({
        email: '',
        country_billing: 'US',
        postal_billing: '',
        country_shipping: 'US',
        postal_shipping: ''
    });
    const [score, setScore] = useState<number | null>(null);
    const [error, setError] = useState<string | null>(null);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value
        }));
      };

    const handleSubmit = async (e: React.FormEvent) => {
        
        e.preventDefault();

        let token: string | null = '';

        try {
            token = localStorage.getItem('token'); // Retrieve token from localStorage
        
            if (!token) {
              setError('Invalid token... Please login again...');
              setScore(null);
              return;
            }
        } catch(error) {
            console.error('Error occurred during token validation:', error);
            setError('An error occurred');
            setScore(null);
        }
        setLoading(true);
        try {
          const response = await fetch(process.env.REACT_APP_API_URL + '/api/score', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(formData)
          });
    
          if (response.ok) {
            const data = await response.json();
            setScore(data.score);
            setError(null);
          } else {
            const errorMessage = await response.text();
            setError(errorMessage);
            setScore(null);
          }
        } catch (error) {
          console.error('Error occurred during API call:', error);
          setError('An error occurred: ' + error);
          setScore(null);
        } finally {
          setLoading(false);
        }
      };

    return (
        <>
        {loading ? (
            <Modal />
            ) : null}
            <div id="app-container">

                {score !== null && !error && <div id="score">Nòt: <span style={{fontStyle:'italic',fontWeight:'bold'}}>{score}</span></div>}

                <input type="text" id="email-input" name="email" value={formData.email} onChange={handleInputChange} placeholder="test@imel.com" required />

                <br></br>
                <br></br>

                <label htmlFor="country_billing">peyi facturation: </label>
                <select className="country_selector" id="country_billing" name="country_billing" value={formData.country_billing} onChange={handleInputChange}>
                    <option value="US">USA</option>
                    <option value="CA">Canada</option>
                </select>
                
                &nbsp;

                <input type="text" id="postalcode-input" name="postal_billing" value={formData.postal_billing} onChange={handleInputChange} placeholder="Kòd postal" size={6} maxLength={6} />
                

                <br></br>
                <br></br>

                <label htmlFor="country_shipping">peyi anbake: </label>
                <select className="country_selector" id="country_shipping" name="country_shipping" value={formData.country_shipping} onChange={handleInputChange}>
                    <option value="US">USA</option>
                    <option value="CA">Canada</option>
                </select>

                &nbsp;

                <input type="text" id="postalcode-input" name="postal_shipping" value={formData.postal_shipping} onChange={handleInputChange} placeholder="Kòd postal" size={6} maxLength={6} />
                
                <br></br>
                <br></br>

                <button type="submit" id="search-button" onClick={handleSubmit}>
                    Jwenn Li
                </button>

                <div id="bandana">
                    <img src={bandana} alt="bandana" height={'120px'}/>
                </div>
            </div>

            {error && <p color="red">Error: {error}</p>}
        </>
    );
};

export default Score;